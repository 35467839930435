import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles,
    ImmediateOutputParameters,
    GenericPosNotSupportedErrorNotes
} from "../../components/server-guide";
import {options} from "../../data/asa";
import {Callout} from "../../components/alert";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        SQLAPI++ library requires SQL Anywhere client API library for C/C++ version 12 or higher.
    </Callout>
);
const sDBString = () => (
    <p>
        Any valid SQL Anywhere connection string
    </p>
);

const UserID = () => (<p>A string containing a user name to use when establishing the connection.</p>);

const Page = () => (
    <ServerGuide server="SQL Anywhere"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 outputParamNotes={() => <ImmediateOutputParameters server="SQL Anywhere"/>}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="SQL Anywhere"
                                                                      nativeCommand="sqlany_cancel"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="SQL Anywhere"
                                                                        cppHeader="asaAPI.h"
                                                                        apiClass="asaAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="SQL Anywhere"
                                                                                handleClass="asaConnectionHandles"
                                                                                cppHeader="asaAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="SQL Anywhere"
                                                                          handleClass="asaCommandHandles"
                                                                          cppHeader="asaAPI.h"/>}
                 errorNotes={() => <GenericPosNotSupportedErrorNotes server="SQL Anywhere"/>}
    />
);

export default Page;

import React from "react";

const options = [
    {
        name: 'SQLANY.LIBS',
        scope: 'api',
        description: `Forces SQLAPI++ Library to use specified SQL Anywhere client library.`,
        valid:
            <>
                Any valid SQL Anywhere client library name list. Names separated by ';' on
                Windows or ':' on other operating systems.
            </>,
        defaultValue:
            <ul>
                <li>Windows - {' '}<code>"dbcapi.dll"</code></li>
                <li>Linux - {' '}<code>"libdbcapi.so"</code></li>
            </ul>
    }
];

export {
    options
};
